import React, { act } from "react";
import { useAppProvider } from "../../root/provider";
import html2canvas from 'html2canvas';
import { X } from "@phosphor-icons/react";

const AthxMiniWindow: React.FC<{ id: string, isMinimized: boolean, title: string }> = ({ id, isMinimized, title }) => {

  const minimize = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    e.stopPropagation();
    toggleMinimizeWindow(id);
  }
  const { toggleMinimizeWindow, deleteAtxhWindow } = useAppProvider();

  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

  const [originalHTML, setOriginalHTML] = React.useState<string>('');

  const generateHTMLImmage = async (element: HTMLElement) => {
    const canvas = await html2canvas(element, {
      logging: false,
      backgroundColor: null,
      useCORS: true
    });
    const dataUrl = canvas.toDataURL(); // base64 PNG
    return dataUrl;
  }

  // React.useEffect(() => {

  //   const cloneOriginal = async () => {
  //     if (isMinimized) return;
  //     const $originalHTML = document.querySelector(`.athx-window-container[id="${id}"] .athx-window`) as HTMLElement;
  //     if (originalHTML === null || typeof originalHTML === 'undefined') {
  //       clearInterval(intervalRef.current as NodeJS.Timeout);
  //       return;
  //     }
  //     try {
  //       const src = await generateHTMLImmage($originalHTML)
  //       setOriginalHTML(src);
  //     } catch (e) { }
  //   }
  //   intervalRef.current && clearInterval(intervalRef.current);
  //   intervalRef.current = setInterval(cloneOriginal, 5000);
  //   setTimeout(() => { cloneOriginal(); }, 1500);
  //   return () => {
  //     clearInterval(intervalRef.current as NodeJS.Timeout);
  //   }
  // }, [intervalRef, setOriginalHTML, id, isMinimized]);

  return (
    <div key={id} className="athx-win-mini" onClick={(e: React.MouseEvent<HTMLDivElement>) => { minimize(e, id) }} >
      <div className="athx-win-mini-header">
        <span className="title">{title}</span>
        <button className="athx-win-mini-closer" onClick={() => { deleteAtxhWindow(id) }}><X size={48} /></button>
      </div>

      {/* <img src={originalHTML} alt="" style={{ maxWidth: 'calc(150px - 1rem - 1rem)', maxHeight: 'calc(150px - 1rem - 1rem)' }} /> */}
    </div>
  )
};

export function ActiveWindows() {

  const { athxWindowsList } = useAppProvider();

  if (athxWindowsList.length === 0) return null;
  return (
    <div className="active-athx-window-list">
      {athxWindowsList.map((athxWindow) => (
        <AthxMiniWindow key={athxWindow.id} id={athxWindow.id} isMinimized={athxWindow.isMinimized} title={athxWindow.title} />
      ))}
    </div>)
}