import React, { useEffect } from 'react';
import '../../styles/index.scss';
import { Desktop } from '../desktop';
import { Footer } from '../footer';
import { AppMenu } from '../menus/app';
import { MenuProvider } from '../menus';
import { SearchMenu } from '../menus/search';
import { NotificationsMenu } from '../menus/notifications';
import { MessagesMenu } from '../menus/messages';
import { EmailsMenu } from '../menus/emails';
import { TasksMenu } from '../menus/tasks';
import { CalendarMenu } from '../menus/calendar';
import { MiniMenu } from '../menus/mini';
import { SocketNotification } from './worker'
import { AppProvider } from './provider';
import { ActiveWindows } from '../menus/windows';
import { WindowPlaceholders } from '../menus/windows/src/placeholders';
import { Loader } from '../loader';
import { AlertContainer } from '../alert';


import { useDispatch } from 'react-redux';
import { store, RootState } from '../../store/index';
import { setProcesses, setEmails, setNotifications, setEvents, setMessages, setTasks } from '../../store/footer';


const Root: React.FC = () => {

  const dispatch = useDispatch();

  setTimeout(() => {
    dispatch(setProcesses(true));
    dispatch(setEmails(true));
    dispatch(setNotifications(true));
    dispatch(setEvents(true));
    dispatch(setMessages(true));
    dispatch(setTasks(true));
  }, 15000);

  
  useEffect(() => {
    const appName = process.env.REACT_APP_NAME || 'undefined';
    if (!appName)
      console.error('REACT_APP_APP_NAME is not defined in .env file');
    document.title = appName;
  }, []);

  useEffect(() => {
    const theme = localStorage.getItem('athx-theme') || 'default';
    document.documentElement.setAttribute('athx-theme', theme);
  }, []);

  useEffect(() => {
    // Disabilita il comportamento di pull-to-refresh sui dispositivi touch
    const disablePullToRefresh = (e: TouchEvent) => {
      // if (e.touches.length > 1 || e.changedTouches[0].clientY > 0) {
      //   e.preventDefault();
      // }
    };

    const disableContextMenu = (e: MouseEvent) => {
      if (process.env.REACT_APP_ENVIROMENT !== 'development')
        e.preventDefault();
    };

    document.addEventListener('touchmove', disablePullToRefresh, { passive: false });
    document.addEventListener('contextmenu', disableContextMenu);

    return () => {
      document.removeEventListener('touchmove', disablePullToRefresh);
      document.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);


  return (

    <>
      <AppProvider>
        <SocketNotification></SocketNotification>
        <span className="filigrana"><img src="athenorix-logo.png" alt="" />Athenorix Theme</span>
        <Desktop></Desktop>
        <MenuProvider>
          <AppMenu></AppMenu>
          <SearchMenu></SearchMenu>
          <NotificationsMenu></NotificationsMenu>
          <MessagesMenu></MessagesMenu>
          <EmailsMenu></EmailsMenu>
          <TasksMenu></TasksMenu>
          <CalendarMenu></CalendarMenu>
          <MiniMenu></MiniMenu>
          <Footer></Footer>
          <WindowPlaceholders></WindowPlaceholders>
          <ActiveWindows></ActiveWindows>
        </MenuProvider>
        <Loader></Loader>
      </AppProvider>
      <AlertContainer />
    </>
  );
}

export default Root;
