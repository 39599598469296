import React from "react";
import '../../../../../styles/src/menus/search/index.scss'
import { Dropdown } from "../../../../dropdown";
import { AthxPopover } from "../../../../popover";


import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../store';
import { setTheme } from '../../../../../store/root/index';
import { setUserLevel } from "../../../../../store/appSettings";
import { Check } from "@phosphor-icons/react";
import { isMobile } from 'react-device-detect';

export const AppBody: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.theme);
  const handleThemeChange = (newTheme: 'default' | 'attractive' | 'energy' | 'vibe' | 'classic') => {
    dispatch(setTheme(newTheme));
  };
  return (
    <div className="athm-app-body">
      <div className="w-100 d-flex f-d-r">

        <Dropdown options={[
          { value: 'default', label: 'Default', theme: 'interactive', ...theme === 'default' ? { icon: Check } : {}, className: 'default-theme' },
          { value: 'attractive', label: 'Attractive', theme: 'interactive', ...theme === 'attractive' ? { icon: Check } : {}, className: 'attractive-theme' },
          { value: 'energy', label: 'Energy', theme: 'interactive', ...theme === 'energy' ? { icon: Check } : {}, className: 'energy-theme' },
          { value: 'vibe', label: 'Vibe', theme: 'interactive', ...theme === 'vibe' ? { icon: Check } : {}, className: 'vibe-theme' },
          { value: 'classic', label: 'Classic', theme: 'interactive', ...theme === 'classic' ? { icon: Check } : {}, className: 'classic-theme' },
        ]} dropdownName="Cambio tema" drClassName="w-50" className={'primary w-100'} onSelect={(value) => {
          handleThemeChange(value as 'default' | 'attractive' | 'energy' | 'vibe' | 'classic');
        }} buttonClassName="athx-btn accent sm p-2 w-100" />
        <AthxPopover showAt={isMobile ? 'top-right' : `top-center`} showOn="focus" className="w-50">
          <Dropdown options={[
            { value: '0', label: 'Nuovo', theme: 'interactive' },
            { value: '1', label: 'Intermedio', theme: 'interactive' },
            { value: '2', label: 'Avanzato', theme: 'interactive' },
          ]} dropdownName="Seleziona l'esperienza" drClassName="w-100" className={'primary w-100'} onSelect={(value) => {
            dispatch(setUserLevel(parseInt(value as string) as 0 | 1 | 2));
          }} buttonClassName="athx-btn accent sm p-2 w-100" />
          <div className="fs-8">
            In base al livello scelto verrano mostrati gli aiuti.
            <ul>
              <li><b>Nuovo</b> - verranno visualizzati tutti gli aiuti</li>
              <li><b>Intermedio</b> - verranno visualizzati solamente in casi poco intuitivi</li>
              <li><b>Avanzato</b> - verranno visualizzati solo in funzionalità nuove e poco intuitive</li>
            </ul>
          </div>
        </AthxPopover>
      </div>
    </div>
  )
}


