import React, { FC, useEffect } from 'react';
import { Icon, X } from '@phosphor-icons/react';
import { useAppProvider } from '../../../../components/root/provider';
import { ShowAlertWindow } from '../../../alert';
export interface ButtonWindowOpenerProps {
  iconName: string;
  url: string;
  icon: Icon;
  title: string;
  id: string;
  onClose: () => void;
  onToggleMinimize: () => void;
  data: any
}
export const ButtonWindowOpener: FC<ButtonWindowOpenerProps> = ({ iconName, url, icon, title, id, onClose, onToggleMinimize, data }) => {
  const { addAthxWindow, athxWindowsList } = useAppProvider();
  const handleDoubleClick = () => {
    // const window = athxWindowsList.find(w => w.id === id);
    // if (window) return;

    if (athxWindowsList.length >= 9) {
      ShowAlertWindow('Errore!', 'Hai attualmente 9 finestre aperte.', 'error', false);
      return;
    }
    addAthxWindow({
      id: id,
      key: '',
      title: title,
      url: url,
      onClose: onClose,
      onToggleMinimize: onToggleMinimize,
      icon: icon,
      data: data,
      isMinimized: false
    });
  }
  const handleContextMenu = (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {

    e.preventDefault();
  }

  return (
    <button className="desktop-icon" onDoubleClick={handleDoubleClick} onContextMenu={handleContextMenu}>
      {React.createElement(icon, { size: 48 })}
      <span>{iconName}</span>
    </button>
  )
}