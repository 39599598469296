import React, { useRef, useState, useEffect, useCallback } from "react";
import { CalendarCheck, CalendarPlus, CaretDown, CaretUp, Spinner, Trash, X, PencilSimple } from "@phosphor-icons/react";
import { DayEvents } from './src/DayEvents';
import { AthxPopover } from "../../../../popover";
import { DropSelect } from "../../../../dropselect";
import {
  ShowAlertWindow,
  alertError,
  alertInfo,
  alertSuccess,
  alertWarning,
  alertInteractive,
  alertPrimary,
  alertSecondary,
  alertAccent
} from "../../../../alert/index";
import { isMobile } from "react-device-detect";

class CalendarError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "CalendarError";
  }
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
export const CalendarBody = () => {
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState("days");
  const calendar = useRef<HTMLDivElement | null>(null);
  const selectedDate = useRef<HTMLDivElement | null>(null);
  const renderedDate = useRef<Date | null>(null); // Ref per tenere traccia della data renderizzata
  const eventDate = useRef<Date | null>(null); // Ref per tenere traccia della data dell'evento
  const eventCrudFormRef = useRef<HTMLFormElement | null>(null); // Ref per tenere traccia della data dell'evento
  const [sending, setSending] = useState(false);
  const [eventId, setEventId] = useState<string>('');

  const fetchDayViewData = useCallback(async () => {
    try {
      if (!renderedDate.current) return;
      fetch((process.env.REACT_APP_BACKEND_FULLHOST as string).concat(`/api/calendar/events&month=${renderedDate.current ? renderedDate.current?.getMonth() + 1 : '0'}&year=${renderedDate.current?.getFullYear()}`))
        .then(response => response.json())
        .then(data => {
          // console.log(data);
        }).catch(error => {
          console.error(error);
        });
    } catch (e) {
      console.error(e);
    }
  }, []);


  useEffect(() => {
    if (view === "days" && (!renderedDate.current || renderedDate.current.getTime() !== date.getTime())) {
      fetchDayViewData();
      renderedDate.current = date; // Aggiorna la data renderizzata
    }
  }, [view, date, fetchDayViewData]);

  const monthNames = [
    "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
  ];

  const dayNames = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"];

  const today = new Date();

  const onClick = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (!(target.classList.contains("calendar-day")) || target.classList.contains("other-month"))
      return;
    if (selectedDate.current)
      selectedDate.current.classList.remove("selected");
    target.classList.add("selected");
    selectedDate.current = target;
  };

  const onEventListClick = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (!(target.closest(".edit-c-e-event")))
      return;
    const $item = target.closest(".edit-c-e-event") as HTMLElement;
    setEventId($item.getAttribute("data-event-id") as string);
    setView("create-event");
  }

  const onDoubleClick = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (!(target.classList.contains("calendar-day")) || target.classList.contains("other-month"))
      return;
    const year = target.getAttribute("data-year");
    const month = target.getAttribute("data-month");
    const day = target.getAttribute("data-day");
    eventDate.current = new Date(parseInt(year as string), parseInt(month as string) - 1, parseInt(day as string));
    setView("events");
  };

  const changeMonth = (increment: number) => { const newDate = new Date(date); newDate.setMonth(date.getMonth() + increment); setDate(newDate); };
  const selectMonth = (monthIndex: number) => { const newDate = new Date(date); newDate.setMonth(monthIndex); setDate(newDate); setView("days"); };
  const selectYear = (year: number) => { const newDate = new Date(date); newDate.setFullYear(year); setDate(newDate); setView("months"); };
  const goToToday = () => { setDate(new Date()); setView("days"); };
  const renderDays = () => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const startDay = (firstDayOfMonth.getDay() + 6) % 7;
    const daysInMonth = lastDayOfMonth.getDate();
    const daysInPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();

    const days = [];

    for (let i = startDay - 1; i >= 0; i--) {
      days.push(
        <div key={`prev-${i}`} className="calendar-day other-month">
          {daysInPrevMonth - i}
        </div>
      );
    }
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const isToday = year === today.getFullYear() && month === today.getMonth() + 1;
    for (let day = 1; day <= daysInMonth; day++)
      days.push(
        <div
          key={day}
          className={`calendar-day ${isToday && day === today.getDate() ? "today" : ""}`}
          data-year={year}
          data-month={month}
          data-day={day}
        >
          {day}
        </div>
      );

    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++)
      days.push(<div key={`next-${i}`} className="calendar-day other-month"> {i} </div>);

    return days;
  };
  const renderMonths = () => monthNames.map((month, index) => (<div key={month} className="calendar-month" onClick={() => selectMonth(index)}>    {month}  </div>));
  const renderYears = () => { const years = []; for (let i = today.getFullYear() - 100; i <= today.getFullYear() + 100; i++)    years.push(<div key={i} className="calendar-year" onClick={() => selectYear(i)}>    {i}  </div>); return years; };

  const addEvent = () => { eventDate.current = new Date(); setView('create-event'); }

  const createEvent = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      // event.stopPropagation();
      setSending(true);


      const formData = new FormData(eventCrudFormRef.current as HTMLFormElement);
      const data: Record<string, any> = {};
      formData.forEach((value, key) => { data[key] = value; });
      data.reminder = data.reminder ? data.reminder === 'on' : false;
      if (eventId)
        data.id = eventId;
      if (!data.hours || !data.minutes || !data.title || !data.description)
        throw new CalendarError(`Inserire ${[data.title === '' ? 'Titolo' : '', data.description === '' ? 'Descrizione' : ''].filter(e => e).join(', ')} per continuare!`);

      /**TODO 
       * 
       * API creazione/modifica evento
      */
      await sleep(2000);
      /**
       * Fine API
       */

      let string = `<p>Evento ${eventId ? 'modificato' : 'creato'} con successo${eventId ? '!' : `<br>in data ${eventDate.current?.toLocaleDateString('it-IT', { year: '2-digit', month: '2-digit', day: '2-digit' })} alle ${data.hours}:${data.minutes}`}</p>`;
      ShowAlertWindow('', string, 'success', true);

      setEventId('');
      setView('days');

    } catch (e) {
      let message = (e as CalendarError).name === 'CalendarError' ? (e as CalendarError).message : 'Si è verificato un errore durante l\'operazione!';
      ShowAlertWindow('Errore!', message, 'error', false);
      console.error(e);
    } finally {
      setSending(false);
      alertInfo('P.S. Nessun evento è stato creato, modificato o eliminato, questa è solo una demo!', true);
    }
  }
  const deleteEvent = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      // event.stopPropagation();
      setSending(true);

      /**TODO 
       * 
       * API eliminazione evento
      */
      await sleep(2000);
      /**
       * Fine API
       */

      let string = `<p>Evento eliminato con successo!</p>`;
      ShowAlertWindow('', string, 'success', true);

      setEventId('');
      setView('days');

    } catch (e) {
      let message = 'Si è verificato un errore durante l\'operazione!';
      ShowAlertWindow('Errore!', message, 'error', false);
      console.error(e);
    } finally {
      setSending(false);
      alertInfo('P.S. Nessun evento è stato creato, modificato o eliminato, questa è solo una demo!', true);
    }
  }

  return (
    <div className="athm-calendar-body" ref={calendar} onClick={onClick}>
      {view === 'create-event' ?
        <div className="athx-crud-events">
          <div className="athx-c-crud-head">
            <span className="athx-c-e-title">{eventId ? 'Modifica evento' : 'Creazione evento'} {eventDate.current && eventDate.current.toLocaleDateString("it-IT", { year: "numeric", month: "2-digit", day: "2-digit" })}</span>
          </div>
          <div className={`athx-c-crud-body ${sending ? 'sending' : ''}`}>
            <form ref={eventCrudFormRef} action="" onSubmit={(e) => e.preventDefault()}>
              <div className="athx-c-crud-time">
                {(() => {
                  const theme = 'interactiive';
                  let options = [];
                  for (let i = 0; i < 24; i++)
                    options.push({ value: i.toString().padStart(2, '0'), label: i.toString().padStart(2, '0'), theme: theme, className: 'd-flex j-c-e a-i-c' });
                  return (<DropSelect
                    options={options}
                    dropdownName="Seleziona l'ora"
                    drClassName="w-49 h-100"
                    className={'primary'}
                    onSelect={() => { }}
                    preselectedValue="12"
                    name="hours"
                    buttonClassName={`athx-btn accent sm p-2 w-100 h-100 j-c-e ${sending ? 'disabled' : ''}`} />)
                })()}
                <span className={`athx-bg-accent h-100 d-flex j-c-c a-i-c w-2 not-form-control ${sending ? 'disabled' : ''}`}>:</span>
                {(() => {
                  const theme = 'interactiive';
                  let options = [];
                  for (let i = 0; i < 60; i++)
                    options.push({ value: i.toString().padStart(2, '0'), label: i.toString().padStart(2, '0'), theme: theme, className: 'd-flex j-c-s a-i-c' });
                  return (<DropSelect
                    options={options}
                    dropdownName="Seleziona i minuti"
                    drClassName="w-49 h-100"
                    className={'primary'}
                    onSelect={() => { }}
                    preselectedValue="00"
                    name="minutes"
                    buttonClassName={`athx-btn accent sm p-0 w-100 h-100 j-c-s ${sending ? 'disabled' : ''}`} />)
                })()}
              </div>
              <div className="athx-c-crud-title">
                <input type="text" name="title" placeholder="Titolo" disabled={sending} />
                <textarea name="description" rows={5} placeholder="Descrizione" disabled={sending}></textarea>
                <div className="form-element-container sm" >
                  <label>
                    <input type="checkbox" name="reminder" className="accent" />
                    <span className="input-checkbox sm primary"></span>
                    <span className="fs-12">Invia reminder 15 min. prima</span>
                  </label>
                </div>
              </div>
            </form>
            {sending ? <div className="sending-overlay">
              <div className="d-flex j-c-c a-i-c"><Spinner size={24} className="spinner" /> attendere...</div>
            </div> : null}
          </div>
          <div className="athx-c-crud-footer">
            <AthxPopover showAt="top-right" showOn="hover" showFor={0}>
              <button onClick={() => { setView('days') }} className="athx-btn outline error" disabled={sending}>{sending ? <Spinner className="spinner" size={24} /> : <X size={24} />}</button>
              <div className="fs-13" style={{ width: '200px' }}>
                {eventId !== '' ? 'Annulla modifica' : 'Annulla creazione'}
              </div>
            </AthxPopover>
            {eventId !== '' &&
              <AthxPopover showAt="top-right" showOn="hover" showFor={0} >
                <button onClick={deleteEvent} title={`Elimina evento`} className="athx-btn error" disabled={sending}>{sending ? <Spinner className="spinner" size={24} /> : <Trash size={24} />}</button>
                <div className="fs-13" style={{ width: '200px' }}>
                  Elimina evento
                </div>
              </AthxPopover>
            }
            <AthxPopover showAt="top-right" showOn="hover" showFor={0}>
              <button onClick={createEvent} className={`athx-btn ${eventId !== '' ? 'warning' : 'success'}`} disabled={sending}>{sending ? <Spinner className="spinner" size={24} /> : (eventId !== '' ? <PencilSimple size={24} /> : <CalendarPlus size={24} />)}</button>
              <div className="fs-13" style={{ width: '200px' }}>
                {eventId !== '' ? 'Modifica evento' : 'Crea evento'}
              </div>
            </AthxPopover>
          </div>
        </div>
        : view === "events" ?
          <div className="athx-c-events">
            <div className="athx-c-e-header">
              <div className="athx-c-e-title">
                <CalendarCheck size={24} />
                <span>{eventDate.current && eventDate.current.toLocaleDateString("it-IT", { year: "numeric", month: "long", day: "numeric" })}</span>
              </div>
              <div className="athx-c-e-controllers">
                <AthxPopover showAt="top-right" showOn="hover" showFor={0}>
                  <button onClick={(e) => { setView('create-event') }}><CalendarPlus size={24} /></button>
                  <div className="fs-13" style={{ width: '200px' }}>
                    Crea un nuovo evento per la data {eventDate.current && eventDate.current.toLocaleDateString("it-IT", { year: "numeric", month: "2-digit", day: "2-digit" })}
                  </div>
                </AthxPopover>

                <button onClick={() => { setView('days') }}><X size={24} /></button>
              </div>
            </div>
            <div className="athx-c-e-body" onClick={onEventListClick}>
              <DayEvents date={eventDate.current ? eventDate.current : new Date()} />
            </div>
          </div>
          :
          <>
            <div className="athx-cal-controllers">
              <div className="athx-cal-today">
                <AthxPopover showAt={`${isMobile ? 'bottom-left' : 'top-left'}`} showOn="hover" showFor={0}>
                  <button onClick={goToToday}>{new Date().toLocaleDateString("it-IT", { year: "numeric", month: "long", day: "numeric" })}</button>
                  <div className="fs-13" style={{ width: '200px' }}>
                    Renderizza il mese corrente
                  </div>
                </AthxPopover>
              </div>
              <div className="athx-cal-a-e">
                <AthxPopover showAt={`${isMobile ? 'bottom-right' : 'top-right'}`} showOn="hover" showFor={0}>
                  <button onClick={addEvent}><CalendarPlus size={24} /></button>
                  <div className="fs-13" style={{ width: '200px' }}>
                    Crea un nuovo evento per la data {new Date().toLocaleDateString("it-IT", { year: "numeric", month: "2-digit", day: "2-digit" })}
                  </div>
                </AthxPopover>
              </div>
            </div>
            <div className="athx-cal-h">
              <AthxPopover showAt="top-left" showOn="hover" showFor={0}>
                <div className="athx-current" onClick={() => setView(view === "days" ? "months" : view === "months" ? "years" : "days")}>
                  {view === "days" && `${monthNames[date.getMonth()]} ${date.getFullYear()}`}
                  {view === "months" && date.getFullYear()}
                  {view === "years" && "Seleziona l'anno"}
                </div>
                <div className="fs-13" style={{ width: '200px' }}>
                  {view === "days" && `Seleziona il mese`}
                  {view === "months" && 'Seleziona l\'anno'}
                  {view === "years" && "Torna al calendario"}
                </div>
              </AthxPopover>
              <div className="next-prev">
                {view === "days" && <button className="prev-next-controller" onClick={() => changeMonth(-1)}><CaretUp /></button>}
                {view === "days" && <button className="prev-next-controller" onClick={() => changeMonth(1)}><CaretDown /></button>}
              </div>
            </div>
            <div className="athx-cal-b">
              {view === "days" && (
                <>
                  <div className="calendar-weekdays">
                    {dayNames.map((day) => (
                      <div key={day} className="calendar-weekday">
                        {day}
                      </div>
                    ))}
                  </div>
                  <div className="calendar-days" onDoubleClick={onDoubleClick}>{renderDays()}</div>
                </>
              )}
              {view === "months" && renderMonths()}
              {view === "years" && renderYears()}
            </div>
          </>
      }
    </div>
  );
};

export default CalendarBody;
