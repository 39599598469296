import React from "react";
import { ArrowsIn, ArrowsOut, Lock as LockIcon, X as IconX } from "@phosphor-icons/react";
import { isMobile } from 'react-device-detect';
export interface WindowHeaderProps {
  icon: any;
  titleContent: string;
  handleMinimize: () => void;
  handleFullscreen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDestroy: () => void;
  handleMouseDown: (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => void;
  onFixedPosition: (e: React.MouseEvent<HTMLDivElement>) => void;
  isFullscreen: boolean;
  isMinimized: boolean;
}
export const WindowHeader: React.FC<WindowHeaderProps> = ({ icon, titleContent, handleMinimize, handleFullscreen, handleDestroy, handleMouseDown, isFullscreen, onFixedPosition, isMinimized }) => {
  const onContextMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // !(isMobile) &&
      setIsContextMenuOpen(true);
  }

  const [isContextMenuOpen, setIsContextMenuOpen] = React.useState(false);
  React.useEffect(() => {

    const onClick = (e: MouseEvent) => {
      setIsContextMenuOpen(false);
    }

    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    }

  }, [setIsContextMenuOpen]);
  return (
    <div className="athx-window-header" onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}>
      <h4 className="athx-window-title">
        <span className="athx-window-locked text-interactive">
          {<LockIcon size={18} />}
        </span>
        {React.createElement(icon, { size: 18 })}
        {titleContent}
        
      </h4>
      <div className="athx-window-actions">
        <button className="athx-window-action m" onClick={handleMinimize}>_</button>
        <div className="athx-window-fixed-menu">
          <button className={`athx-window-action f ${isFullscreen ? 'active' : ''}`} onClick={handleFullscreen} onContextMenu={onContextMenu} >{isFullscreen ? <ArrowsIn size={24} /> : <ArrowsOut size={24} />}</button>
          {isContextMenuOpen && <div className="athx-window-fixed-positions">
            <div className="athx-window-positions-container" onClick={onFixedPosition}>

              {/* Posizioni full height 33% width */}
              <button className="athx-window-fixed-position s" data-pos={'33 x 100'} data-position={JSON.stringify({ width: 33.33333333, height: 100, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position c" data-pos={'33 x 100'} data-position={JSON.stringify({ width: 33.33333333, height: 100, top: 0, left: 33.33333333 })}></button>
              <button className="athx-window-fixed-position e" data-pos={'33 x 100'} data-position={JSON.stringify({ width: 33.33333333, height: 100, top: 0, left: 66.66666666 })}></button>

              {/* posizioni 25% height 25% width */}

              <button className="athx-window-fixed-position ts50" data-pos={'25 x 25'} data-position={JSON.stringify({ width: 50, height: 50, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position te50" data-pos={'25 x 25'} data-position={JSON.stringify({ width: 50, height: 50, top: 0, left: 50 })}></button>
              <button className="athx-window-fixed-position bs50" data-pos={'25 x 25'} data-position={JSON.stringify({ width: 50, height: 50, top: 50, left: 0 })}></button>
              <button className="athx-window-fixed-position be50" data-pos={'25 x 25'} data-position={JSON.stringify({ width: 50, height: 50, top: 50, left: 50 })}></button>

            
              {/* posizioni 100% height 50% width */}
              <button className="athx-window-fixed-position s50" data-pos={'50 x 100'} data-position={JSON.stringify({ width: 50, height: 100, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position e50" data-pos={'50 x 100'} data-position={JSON.stringify({ width: 50, height: 100, top: 0, left: 50 })}></button>
              {/* posizione 50% width 100% height centrale */}
              <button className="athx-window-fixed-position c50" data-pos={'50 x 100'} data-position={JSON.stringify({ width: 50, height: 100, top: 0, left: 25 })}></button>


              {/* posizione 66% width 100% height laterali */}
              <button className="athx-window-fixed-position w66s" data-pos={'66 x 100'} data-position={JSON.stringify({ width: 66.66666666, height: 100, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position w66e" data-pos={'66 x 100'} data-position={JSON.stringify({ width: 66.66666666, height: 100, top: 0, left: 33.33333333 })}></button>

              {/* posizione 66 width 66% height angolati */}
              <button className="athx-window-fixed-position w66h66ts" data-pos={'66 x 66'} data-position={JSON.stringify({ width: 66.66666666, height: 66.66666666, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position w66h66te" data-pos={'66 x 66'} data-position={JSON.stringify({ width: 66.66666666, height: 66.66666666, top: 0, left: 33.33333333 })}></button>
              <button className="athx-window-fixed-position w66h66be" data-pos={'66 x 66'} data-position={JSON.stringify({ width: 66.66666666, height: 66.66666666, top: 33.33333333, left: 0 })}></button>
              <button className="athx-window-fixed-position w66h66bs" data-pos={'66 x 66'} data-position={JSON.stringify({ width: 66.66666666, height: 66.66666666, top: 33.33333333, left: 33.33333333 })}></button>

              
              {/* posizioni 100% height 25% width */}
              <button className="athx-window-fixed-position s25" data-pos={'25 x 100'} data-position={JSON.stringify({ width: 25, height: 100, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position w25-s25" data-pos={'25 x 100'} data-position={JSON.stringify({ width: 25, height: 100, top: 0, left: 25 })}></button>
              <button className="athx-window-fixed-position w25-e25" data-pos={'25 x 100'} data-position={JSON.stringify({ width: 25, height: 100, top: 0, left: 50 })}></button>
              <button className="athx-window-fixed-position e25" data-pos={'25 x 100'} data-position={JSON.stringify({ width: 25, height: 100, top: 0, left: 75 })}></button>


              {/* non utilizzato, non ho idea di che misure metterci */}
              <button className="athx-window-fixed-position transparent" data-pos={'NON UTILIZZATO'}></button>

              {/* posizioni 33% height 33% width  in alto*/}
              <button className="athx-window-fixed-position ts33 fsm" data-pos={'33x33'} data-position={JSON.stringify({ width: 33.33333333, height: 33.33333333, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position tc33 fsm" data-pos={'33x33'} data-position={JSON.stringify({ width: 33.33333333, height: 33.33333333, top: 0, left: 33.33333333 })}></button>
              <button className="athx-window-fixed-position te33 fsm" data-pos={'33x33'} data-position={JSON.stringify({ width: 33.33333333, height: 33.33333333, top: 0, left: 66.66666666 })}></button>


              {/* posizioni 33% height 33% width  in centro*/}
              <button className="athx-window-fixed-position cs33 fsm" data-pos={'33x33'} data-position={JSON.stringify({ width: 33.33333333, height: 33.33333333, top: 33.33333333, left: 0 })}></button>
              <button className="athx-window-fixed-position cc33 fsm" data-pos={'33x33'} data-position={JSON.stringify({ width: 33.33333333, height: 33.33333333, top: 33.33333333, left: 33.33333333 })}></button>
              <button className="athx-window-fixed-position ce33 fsm" data-pos={'33x33'} data-position={JSON.stringify({ width: 33.33333333, height: 33.33333333, top: 33.33333333, left: 66.66666666 })}></button>


              {/* posizioni 33% height 33% width  in basso*/}
              <button className="athx-window-fixed-position bs33 fsm" data-pos={'33x33'} data-position={JSON.stringify({ width: 33.33333333, height: 33.33333333, top: 66.66666666, left: 0 })}></button>
              <button className="athx-window-fixed-position bc33 fsm" data-pos={'33x33'} data-position={JSON.stringify({ width: 33.33333333, height: 33.33333333, top: 66.66666666, left: 33.33333333 })}></button>
              <button className="athx-window-fixed-position be33 fsm" data-pos={'33x33'} data-position={JSON.stringify({ width: 33.33333333, height: 33.33333333, top: 66.66666666, left: 66.66666666 })}></button>

              


              {/* posizioni 66% height 66% width  in alto*/}
              <button className="athx-window-fixed-position ts66" data-pos={'33 x 66'} data-position={JSON.stringify({ width: 33.33333333, height: 66.66666666, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position tc66" data-pos={'33 x 66'} data-position={JSON.stringify({ width: 33.33333333, height: 66.66666666, top: 0, left: 33.33333333 })}></button>
              <button className="athx-window-fixed-position te66" data-pos={'33 x 66'} data-position={JSON.stringify({ width: 33.33333333, height: 66.66666666, top: 0, left: 66.66666666 })}></button>


              {/* posizioni 66% height 66% width  in basso*/}
              <button className="athx-window-fixed-position bs66" data-pos={'33 x 66'} data-position={JSON.stringify({ width: 33.33333333, height: 66.66666666, top: 33.33333333, left: 0 })}></button>
              <button className="athx-window-fixed-position bc66" data-pos={'33 x 66'} data-position={JSON.stringify({ width: 33.33333333, height: 66.66666666, top: 33.33333333, left: 33.33333333 })}></button>
              <button className="athx-window-fixed-position be66" data-pos={'33 x 66'} data-position={JSON.stringify({ width: 33.33333333, height: 66.66666666, top: 33.33333333, left: 66.66666666 })}></button>


              {/* posizioni 33% height 100% width */}
              <button className="athx-window-fixed-position t33-100" data-pos={'100 x 33'} data-position={JSON.stringify({ width: 100, height: 33.33333333, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position c33-100" data-pos={'100 x 33'} data-position={JSON.stringify({ width: 100, height: 33.33333333, top: 33.33333333, left: 0 })}></button>
              <button className="athx-window-fixed-position b33-100" data-pos={'100 x 33'} data-position={JSON.stringify({ width: 100, height: 33.33333333, top: 66.66666666, left: 0 })}></button>


              {/* posizioni 50% height 100% width */}
              <button className="athx-window-fixed-position t50-100" data-pos={'100 x 50'} data-position={JSON.stringify({ width: 100, height: 50, top: 0, left: 0 })}></button>
              <button className="athx-window-fixed-position b50-100" data-pos={'100 x 50'} data-position={JSON.stringify({ width: 100, height: 50, top: 50, left: 0 })}></button>


              
              {/* posizioni 33% height 66% width a sinistra*/}
              <button className="athx-window-fixed-position w66h33st" data-pos={'66 x 33'} data-position={JSON.stringify({ width: 66.66666666, height: 33.33333333, top: 0,            left: 0 })}></button>
              <button className="athx-window-fixed-position w66h33sc" data-pos={'66 x 33'} data-position={JSON.stringify({ width: 66.66666666, height: 33.33333333, top: 33.33333333,  left: 0 })}></button>
              <button className="athx-window-fixed-position w66h33sb" data-pos={'66 x 33'} data-position={JSON.stringify({ width: 66.66666666, height: 33.33333333, top: 66.66666666,  left: 0 })}></button>

              {/* posizioni 33% height 66% width a destra*/}
              <button className="athx-window-fixed-position w66h33et" data-pos={'66 x 33'} data-position={JSON.stringify({ width: 66.66666666, height: 33.33333333, top: 0, left: 33.33333333 })}></button>
              <button className="athx-window-fixed-position w66h33ec" data-pos={'66 x 33'} data-position={JSON.stringify({ width: 66.66666666, height: 33.33333333, top: 33.33333333, left: 33.33333333 })}></button>
              <button className="athx-window-fixed-position w66h33eb" data-pos={'66 x 33'} data-position={JSON.stringify({ width: 66.66666666, height: 33.33333333, top: 66.66666666, left: 33.33333333 })}></button>

            </div>
          </div>}
        </div>
        <button className="athx-window-action d" onClick={handleDestroy}><IconX size={24} /></button>
      </div>
    </div>
  )
}