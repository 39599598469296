import React, { FC } from 'react';
import { useAppProvider } from '../../root/provider';
import { AppWindow, FrameCorners, Resize, ShuffleSimple, X } from '@phosphor-icons/react';
import { Dropdown } from '../../dropdown';
interface CenterMenuProps {
  buttons?: { id: string; toggleMinimize: () => void }[];
}

export const CenterMenu: FC<CenterMenuProps> = ({ buttons }) => {
  const { athxWindowsList, toggleMinimizeWindow, deleteAtxhWindow } = useAppProvider();
  const [isContextMenu, setIsContextMenu] = React.useState(false);

  const onContextMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsContextMenu(true);
  }

  React.useEffect(() => {
    const closeContextMenu = () => {
      setIsContextMenu(false);
    }
    document.addEventListener('click', closeContextMenu);
    return () => {
      document.removeEventListener('click', closeContextMenu);
    }
  }, [setIsContextMenu])
  const onDoubleClick = () => {
    if (athxWindowsList.some(w => !(w.isMinimized)))
      athxWindowsList.forEach(w => toggleMinimizeWindow(w.id, true));
    else
      athxWindowsList.forEach(w => toggleMinimizeWindow(w.id, false));
  }

  const switchMinimized = () => {
    athxWindowsList.forEach(w => toggleMinimizeWindow(w.id));
  }
  const maximixeAll = () => {
    athxWindowsList.forEach(w => toggleMinimizeWindow(w.id, false));
  }
  const minimizeAll = () => {
    athxWindowsList.forEach(w => toggleMinimizeWindow(w.id, true));
  }
  const closeAll = () => {
    athxWindowsList.forEach(w => deleteAtxhWindow(w.id));
  }

  return (
    <div className="f-item center-menu">
      <button className={`f-menu-trigger active-list-toggler ${isContextMenu && 'context'} ${athxWindowsList.length > 0 ? 'active' : ''}`} onDoubleClick={onDoubleClick} onContextMenu={onContextMenu}>
        <AppWindow size={36} />

      </button>
      {isContextMenu && <>
        <div className="active-list-toggler-menu">
          <button className='toggler-menu-item' onClick={switchMinimized}>Scambia <ShuffleSimple size={24} /></button>
          <button className='toggler-menu-item' onClick={maximixeAll}>Massimizza tutti <FrameCorners size={24} /></button>
          <button className='toggler-menu-item' onClick={minimizeAll}>Minimizza tutti <Resize size={24} /></button>
          <button className='toggler-menu-item' onClick={closeAll}>Chiudi tutti <X size={24} color={'var(--error)'} /></button>
        </div>
      </>}
    </div>
  );
};